$color-primary: #655E93;
$color-primary-light: #F3F1FF;
$color-secondary: #81a8d4;
$color-secondary-light: #D9E1F6;
$font-bold: 700;
$font-medium: 600;
$font-regular: 400;
$font-light: 300;

// $color-primary: #040508;
// $color-primary-light: #F3F1FF;
// $color-secondary: #a27830;
// $color-secondary-light: #D9E1F6;
// $font-bold: 700;
// $font-medium: 600;
// $font-regular: 400;
// $font-light: 300;