@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Oriya:wght@100..900&display=swap');




@import "../css/variables.scss";

body {
    font-family: 'Manrope', 'Noto Sans Oriya', sans-serif;
    font-size: 16px;
    font-weight: 500;
}

input, textarea, select {
    font-family: 'Manrope', 'Noto Sans Oriya', sans-serif;
}
.css-13cymwt-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 45px;
    min-width: max-content;
    outline: 0!important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
}
.component-loader {
    background: #ffffffd4;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
    z-index: 2;
    min-height: 200px;

    .loader-icon {
        width: 120px;
        height: 120px;
    }

    .loader {
        width: 80px;
        height: 80px;
        background-image: url('../../assets/images/ailoader.gif');
        background-size: contain;

    }
}

.font-12 {
    font-size: 12px;
}

.font-14 {
    font-size: 14px;
}

.font-16 {
    font-size: 16px;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}

.font-22 {
    font-size: 22px;
}

.font-24 {
    font-size: 24px;
}

.font-28 {
    font-size: 28px;
}

.heading-1 {
    font-size: 29px;
    font-weight: 500;
}

.font-bold {
    font-weight: $font-bold;
}

.font-medium {
    font-weight: $font-medium;
}

.font-light {
    font-weight: $font-light;
}

.font-regular {
    font-weight: $font-regular;
}

a {
    text-decoration: none;
    transition: 0.4s;

    &:hover {
        color: $color-secondary !important;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.text-brand {
    color: $color-primary;
    &:hover {
        cursor: pointer;
    }
}
.bg-brand{background-color: $color-primary;}
.bg-brand-light{background-color: $color-primary-light;}
.btn-brand-1 {
    background-color: $color-primary;
    color: #fff;
    border: 0;
    font-size: 15px;
    font-weight: 500;
    font-weight: 500;
    height: 40px;
    border: 0;
    padding: 7px 27px;
    line-height: 25px;

    &:hover {
        background-color: $color-secondary;
        color: #fff;
    }
}

.btn-brand-light {
    background-color: $color-primary-light;
    color: $color-primary;
    font-weight: 500;
    font-size: 15px;
    border: 0;
    height: 40px;

    &:hover {
        background-color: $color-primary;
        color: #fff !important;
    }
}

.btn-brand-outline-light {
    @extend .btn-brand-light;
    border: 1px solid $color-primary;
}

.checkbox-danger {
    .form-check-input:checked {
        background-color: #a50505 !important;
        border-color: #a50505 !important;
    }
}

.checkbox-success {
    .form-check-input:checked {
        background-color: #098807 !important;
        border-color: #098807 !important;
    }
}

.checkbox-warning {
    .form-check-input:checked {
        background-color: #db6409 !important;
        border-color: #db6409 !important;
        
    }
   
}

.labe-text-dark {
    .form-check-label {
        color: #000 !important;
    }
}
.no-data-flound {
    width: 100%;
    height: 45vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding: 15px;
    border-radius: 6px;
}

// left menu css
.site-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;

    .header-nav {
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

.layout-veritcle-menu {
    display: flex;
    min-height: 100vh;

    .verticle-menu {
        background-color: #fff;
        width: 84px;
        height: calc(100vh - 70px);
        position: sticky;
        top: 71px;
        padding-top: 35px;
        z-index: 4;

        &::before {
            content: "";
            width: 50px;
            height: 50px;
            // background-image: url('../../assets/images/page-corner.svg');
            background-size: contain;
            position: fixed;
            left: 77px;
            top: 79.5px;
            background-repeat: no-repeat;
        }

        .menu-list {
            .menu-item {
                transition: 0.3s;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 70px;
                height: 44px;
                border-radius: 22px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 15px;

                &:hover,
                &:focus {
                    opacity: 1;
                    background-color: $color-primary-light;
                }

                .menu-icon {
                    width: 22px;
                }
            }

            .dropdown-toggle {
                @extend .menu-item;
                border: 0;
                background-color: transparent;


                &::after {
                    border: 0;
                }

                &.show {
                    background-color: $color-primary-light;
                    opacity: 1;
                }
            }

            .dropend {
                text-align: center;
            }

            .dropdown-menu {
                left: -3px !important;
                border-radius: 0 6px 6px 0;
                background-color: $color-primary;

                .dropdown-item {
                    color: #fff;
                    font-size: 14px;
                    padding: 9px 13px;
                    margin-bottom: 3px;

                    &:hover {
                        background-color: #ffffff2b;
                        color: #ffffff !important;
                    }
                }
            }
        }

        .tooltip-inner {
            background-color: #343434 !important;
        }
    }

    .app-body {
        width: calc(100% - 75px);
        padding-top: 100px;
    }
}

// header 

.site-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;

    .header-nav {
        .navbar {
            padding: 0;
        }
    }

    .header-nav {
        background-color: #fff;

        .v-devider {
            display: inline-block;
            width: 1px;
            background: #525252;
            height: 20px;
            margin: 0 15px;
        }
    }

    .profile-dd {

        .dropdown-menu {
            min-width: 200px;
            top: 68px;
            border: 0;
            box-shadow: 0px 3px 10px #0000002b;

            .dropdown-item:hover a,
            .dropdown-item:hover .icon {
                color: #000 !important;
            }
        }

        .btn {
            background-color: #F8F8F8;
            text-align: left;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 0;

            &::after {
                border: 0;
            }

            border-radius: 30px;
            padding: 7px 10px;
        }

        .icon {
            width: 18px;
            height: 18px;
            margin-right: -4px;
            color: #4b4b4b;
        }
    }

    .help-btn {
        background-color: #F8F8FF;
        font-size: 14px;
        color: $color-primary;
        font-weight: $font-medium;
        height: 48px;
        padding: 8px 15px;
    }
}

.back-arrow {
    position: relative;
    z-index: 1;
}

// App Body 
.app-body {
    background: linear-gradient(90deg, #E4E1FC 0%, #D0E0F1 101.47%);
    padding-left: 15px;
    padding-right: 15px;
    overflow-x: hidden;
}
.collapse-page{
    gap: 15px;
   display: flex; 
   .data-container{width: 100%;}
   .filter-container{width: 650px;overflow: hidden;transition: 0.3s;margin-right: -670px;}
   .filter-container.open-filter{margin-right: 0;}
   .filter-card{
    border: 0;
    .card-header{
        display: flex;
        justify-content: space-between;
        .close-btn{color: #333;}
    }
    .card-title{font-size: 18px;margin-bottom: 0;font-weight: $font-medium;}
    .offcanvas-body{
        height: calc(100vh - 150px);
    }
}
}

.page-title {
    font-size: 24px;
    font-weight: $font-bold;
}

.tab-style-1 {
    background-color: #fff;
    width: max-content;
    border-radius: 6px;

    .nav-link {
        color: #000;
        font-size: 14px;
        padding: 11px 25px;
    }

    .nav-link.active {
        background-color: $color-primary;
    }
}

.form-control:focus,
.form-select:focus {
    box-shadow: none;
    border-color: #ddd;
}

.filter-option {
    height: 40px;
}
.filter-option.no-zindex{z-index: 1;}
.pagination-control{
    .form-select{
        height: 30px;
    padding: 3px 25px 5px 16px;
    font-size: 14px;
    }
}

.table-search-box {
    .form-control {
        height: 38px;
        border: 0;
        padding-left: 0;

        &::placeholder {
            color: #8c8989;
        }
    }

    .input-group-text {
        background-color: #fff;
        border: 0;
        height: 38px;
    }

    .icon {
        width: 20px;
        height: 20px;
        color: #676767;
    }
}
.css-qbdosj-Input {
    visibility: visible;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: inline-grid;
    grid-area: 1/1/2/3;
    grid-template-columns: 0 min-content;
    margin: 2px;
    padding-bottom: 2px;
    padding-top: 2px;
    color: hsl(0, 0%, 20%);
    box-sizing: border-box;
}
.table-card {
    position: relative;
    background-color: #fff;
    border-radius: 6px;
    padding: 12px;
    

    .table-heading {
        font-size: 18px;
        margin-bottom: 0;
    }

    .table-container {
        border-radius: 10px;
        border-color: #F1F1F1;
        padding: 0;
        margin-bottom: 12px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
       
        
    }

    .table-style {
        margin-bottom: 0;

        thead th {
            font-size: 12px;
            font-weight: $font-medium;
            color: #101828;
            border: 0;
            text-align: center;  
            border: 1px solid #efeded;
            border-top: 0;  
            &:first-child{border-left: 0;} 
            background: #E4E1FC 
           
        }

        tbody tr td {
            font-size: 14px;
            font-weight: 500;
            color: #101828;
            border: 0;
            text-align: center;
            border: 1px solid #efeded;
            // border-bottom: 0;
            // &:first-child{border-left: 0;}
        }

        .action-col {
            text-align: center;
        }

        .action-btns {
            text-align: center;
            display: flex;
            gap: 5px;
            justify-content: center;

            .action-btn {
                padding: 5px 7px;
                background-color: #F5F5F5;
                color: #333;
                border: 0;
                transition: 0.3s;
                display: flex;
                align-items: center;
                font-size: 12px;
                font-weight: 600;
                gap: 6px;

                .icon {
                    width: 18px;
                    height: 18px;
                }

                &:hover {
                    box-shadow: 0px 0px 10px #0000004b;
                }
            }

            .btn-delete {
                background-color: #FDECEC;
                color: #D53727
            }

            .btn-view {
                background-color: #ECFDF3;
                color: #027A48;
                border-radius: 15px;
            }
        }

    }

    .pagination {
        margin-bottom: 0;
        justify-content: end;

        .page-item {
            .page-link {
                padding: 4px 12px;
                font-size: 14px;
                color: #333;
            }

            &.active .page-link {
                background-color: $color-primary;
                color: #fff;
                border-color: #fff;
            }
        }
    }
}

.tab-style-2 {
    gap: 15px;
    flex-wrap: nowrap;

    .nav-item {
        width: 100%;
    }

    .nav-link {
        background-color: #fff;
        width: 100%;
        padding: 15px;
        font-size: 18px;
        color: #000;
        display: flex;
        align-items: center;
        gap: 10px;
        border: 1px solid transparent;

        &.active {
            background-color: #E2EFFF;
            color: #000;
            border-color: #1977F3;
        }
    }
}

.name-avtart {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    width: 55px;
    border-radius: 50%;
    background-color: $color-primary-light;
    color: $color-primary;
    font-weight: $font-bold;
    font-size: 20px;
}

.status-badge {
    padding: 5px 14px;
    font-weight: $font-medium;
    border-radius: 6px;
    color: #000;
    font-size: 14px;

    &.active {
        background-color: #ECFDF3;
        color: #027A48;
    }

    &.deactive {
        background-color: #F3F3F3;
        color: #333;
    }
}

.offcanvas {
    border-radius: 24px 0 0 24px;
    min-width: 590px;

    .offcanvas-title {
        font-size: 18px;
        font-weight: $font-medium;
    }

    .offcanvas-header {
        border-bottom: 1px solid #e8e6e6;
    }
}

.status-badge.active-2 {
    color: #92935E;
    background-color: #FBFAE9;
}

.perce-adon {
    .form-control{border-right: 0;}
    .input-group-text {
        background-color: #fff;
        border-left: 0;
       
        span {
        display: inline-flex;
        background: #655f94;
        color: #fff;
        font-size: 11px;
        width: 28px;
        height: 28px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        padding: 5px;
        font-weight: 600;
    }
}
}

.form-style {
    display: contents;

    .form-floating>label {
        font-size: 14px;
        padding: 7px 13px;
        color: #676767;
    }

    .form-floating .form-control,
    .form-floating .form-select {
        height: 38px;
        min-height: 38px;
        padding: 6px 12px !important;
        font-size: 15px;
    }

    .form-floating>.form-control:focus~label,
    .form-floating>.form-control:not(:placeholder-shown)~label,
    .form-floating>.form-control-plaintext~label,
    .form-floating>.form-select~label {
        top: 0;
        width: fit-content;
        background: #fff;
        opacity: 1;
        height: 20px;
        padding: 0px 4px;
        left: 5px;
        font-size: 12px;
        height: 17px
    }

    .form-floating>.form-select~label {
        left: 8px;
    }

    .form-select:focus {
        box-shadow: none;
        border-color: #ddd;
    }
}

.status-badge {
    padding: 5px 14px;
    font-weight: $font-medium;
    border-radius: 6px;
    color: #000;
    font-size: 14px;

    &.active {
        background-color: #ECFDF3;
        color: #027A48;
    }
    &.rejected {
        background-color:#f5dfdf;
        color: rgb(238, 99, 99);
    }
    &.not-filled {
        background-color: #f3f5d7;
        color: rgb(231, 171, 58);        
    }
    &.upload {
        background-color: #ECFDF3;
        color: #027A48;
    }
    &.callback {
        background-color: #f3f5d7;
        color: rgb(231, 171, 58);        
    }
    &.pending {
        background-color: #fdfdd9;
        color: rgb(167, 164, 12);        
    }

    &.deactive {
        background-color: #FFFFFF;
        color: #333;
    }
    &.stockist {
        background-color: #f7e1e1;
        color: #f16f6f;
    }
    &.franchasee {
        background-color: #eaf6fc;
        color: #49c3f3;
    }

    &.reject {
        background-color:#f5dfdf;
        color: rgb(238, 99, 99);
        
        &:hover {
            box-shadow: 0px 0px 10px #0000004b;
        }
    }

    &.approve {
        background-color: #ECFDF3;
        color: #027A48;
        
        &:hover {
            box-shadow: 0px 0px 10px #0000004b;
        }
    }
}

.form-check {
    .form-check-label {
        font-size: 16px;
        color: #6D6D6D;
    }

    .form-check-input:checked {
        background-color: $color-primary;
        border-color: $color-primary;
    }
}

.upload-btn-container {
    position: relative;

    img {
        width: 200px;
        height: auto;
    }

    input {
        display: none;
    }

    label {
        width: 100%;
        height: 55px;
        background: $color-primary-light;
        border: 1px dashed $color-primary;
        color: $color-primary;
        font-size: 14px;
        text-transform: capitalize;
        border-radius: 6px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;

    }

    .tb-img-view {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.chat-box {
    height: calc(100vh - 410px);
    overflow-y: auto;

    .chat-card {
        background-color: #e9e9eb !important;
    }

    .chat-corner-left::before {
        content: "";
        width: 24px;
        height: 38px;
        position: absolute;
        left: -5px;
        background-image: url('../images/chat-corner-left.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        bottom: -15px;
    }

    .chat-corner-right::before {
        content: "";
        width: 24px;
        height: 38px;
        position: absolute;
        right: -5px;
        background-image: url('../images/chat-corner-right.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        bottom: -15px;
    }
}

.loader2 {
    position: absolute;
    left: 44%;
    top: 38vh;
    height: 150px !important;
    width: 150px !important;
}

.css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    z-index: 50; 
  }
  
  .css-1jqq78o-placeholder {
    grid-area: 1/1/2/3;
    color:  #676767;
    margin-left: 2px;
    margin-right: 2px;
    box-sizing: border-box;
    font-size: 14px;   
  }

  .css-1jqq78o-placeholder {
    grid-area: 1/1/2/3;
    // color: hsl(0deg 1.49% 37.24%);
    margin-left: 2px;
    margin-right: 2px;
    box-sizing: border-box;
    text-align: left;
    z-index: 100;
}

// responsive css
@media (max-width: 767px) {}



.react-datepicker-wrapper{
    width:88% !important;
    .react-datepicker__input-container{
        max-width: 100% !important;
    }
}

.react-time-picker__wrapper{
    border: none !important;
}

::placeholder {
    font-size: 14px;
}

.img-mapper-img{
    z-index: 0 !important;
}

.img-mapper-canvas{
    z-index: 0 !important;
}
